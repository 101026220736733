import ModalView from '@/js/app/generic/views/modal';
import yuyan from '@/js/app/yuyan';
import template from '../templates/modal.hbs';

export default class ErrorModalView extends ModalView {
    preinitialize(options) {
        this.title = '';
        this.content = '';
        this.handleError(options.error);
        this.preinitializeModal();
    }

    render() {
        console.debug('ErrorModal#render');

        this.renderModal({ centered: true }).modalContent.innerHTML = template({
            title: this.title,
            content: this.content
        });

        return this;
    }

    handleError(error) {
        console.debug('ErrorModal#handleError');

        if (error.name === 'ValidationError') {
            this.title = yuyan.t('error.validation.title');
            this.content = this.processValidationErrorContent(error);
        } else if (error.name === 'ConflictError') {
            this.title = yuyan.t('error.conflict.title');
            this.content = yuyan.t(`error.conflict.${error.field.name}.${error.field.message}`);
        } else if (error.name === 'BillingRobotError') {
            this.title = yuyan.t('error.billing_robot.title');
            this.content = this.processBillingRobotErrorContent(error);
        } else if (error.name === 'UnprocessableEntityError') {
            this.title = yuyan.t('error.unprocessable_entity.title');
            this.content = yuyan.t(`error.unprocessable_entity.${error.entity}.${error.part}.${error.reason}`);
        } else if (error.name === 'GiraffeError') {
            this.title = yuyan.t('error.giraffe');
            this.content = `${error.code}: ${error.message}`;
        } else if (error.name === 'ConsusUiError') {
            this.title = yuyan.t('error.title');
            this.content = yuyan.t(`error.ui.${error.message}`);
        } else {
            this.title = yuyan.t('error.title');
            this.content = 'Something went wrong';
        }
    }

    processValidationErrorContent(error) {
        console.debug('ErrorModal#processValidationErrorContent');

        let content = '';

        error.fields.forEach(field => {
            content += `${field.name}: ${field.message}<br>`;
        });

        return content;
    }

    processBillingRobotErrorContent(error) {
        console.debug('ErrorModal#processBillingRobotErrorContent');

        let content = '';

        if (Array.isArray(error.errors)) {
            content += '<ol start="0">';
            error.errors.forEach((e) => {
                content += `<li>`;

                if (e instanceof Object) {
                    content += e.code ? `${e.code} (${e.message})` : 'No error';

                    content += '<ul>';
                    if (Array.isArray(e.individual)) {
                        content += `<li>individual`;

                        content += '<ol start="0">';
                        e.individual.forEach((individual) => {
                            if (individual instanceof Object) {
                                content += `<li>${individual.code} (${individual.message})</li>`;
                            }
                        });
                        content += '</ol>';
                        content += '</li>';
                    }
                    if (Array.isArray(e.payment)) {
                        content += `<li>payment`;

                        content += '<ol start="0">';
                        e.payment.forEach((payment) => {
                            if (payment instanceof Object) {
                                content += `<li>${payment.code} (${payment.message})</li>`;
                            }
                        });
                        content += '</ol>';
                        content += '</li>';
                    }
                    content += '</ul>';
                } else {
                    content += `No error`;
                }

                content += '</li>';
            });
            content += '</ol>';
        }

        return content;
    }
}
