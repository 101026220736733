var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"nav-item dropdown\">\n                <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n                    "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.customer-relations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":50}}}))
    + "\n                </a>\n                <div class=\"dropdown-menu\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:customers",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":20,"column":26},"end":{"line":20,"column":56}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":133}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:contracts",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":56}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":133}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:product_contracts",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":22,"column":26},"end":{"line":22,"column":64}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":157}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":23,"column":26},"end":{"line":23,"column":45}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":122}}})) != null ? stack1 : "")
    + "\n                </div>\n            </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#customers\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.customers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":101},"end":{"line":20,"column":122}}}))
    + "</a>";
},"4":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#contracts\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.contracts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":101},"end":{"line":21,"column":122}}}))
    + "</a>";
},"6":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#product-contracts\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.product-contracts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":117},"end":{"line":22,"column":146}}}))
    + "</a>";
},"8":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#offenders\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.offenders",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":90},"end":{"line":23,"column":111}}}))
    + "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"nav-item dropdown\">\n                <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n                    "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.pm",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":34}}}))
    + "\n                </a>\n\n                <div class=\"dropdown-menu\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.leasing_status",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":35,"column":26},"end":{"line":35,"column":69}}}),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":35,"column":164}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.leasing_status",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":36,"column":26},"end":{"line":36,"column":69}}}),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":36,"column":166}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":37,"column":26},"end":{"line":37,"column":54}}}),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":37,"column":154}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.room_occupancy",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":38,"column":26},"end":{"line":38,"column":69}}}),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":38,"column":164}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.tenants",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":39,"column":26},"end":{"line":39,"column":62}}}),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":39,"column":141}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.rental_discount",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":40,"column":26},"end":{"line":40,"column":70}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":40,"column":167}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.price_check",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":41,"column":26},"end":{"line":41,"column":66}}}),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":41,"column":155}}})) != null ? stack1 : "")
    + "\n                </div>\n            </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/leasing-status\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.leasing-status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":127},"end":{"line":35,"column":153}}}))
    + "</a>";
},"13":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/leasing-history\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.leasing-history",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":128},"end":{"line":36,"column":155}}}))
    + "</a>";
},"15":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/leasing-speed\" class=\"dropdown-item d-none\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.leasing-speed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":118},"end":{"line":37,"column":143}}}))
    + "</a>";
},"17":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/room-occupancy\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.room-occupancy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":127},"end":{"line":38,"column":153}}}))
    + "</a>";
},"19":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/tenant\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.tenant",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":112},"end":{"line":39,"column":130}}}))
    + "</a>";
},"21":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/rental-discount\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.rental-discount",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":129},"end":{"line":40,"column":156}}}))
    + "</a>";
},"23":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/price-check\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.price-check",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":121},"end":{"line":41,"column":144}}}))
    + "</a>";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"nav-item dropdown\">\n                <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n                    "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.reports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":20},"end":{"line":49,"column":39}}}))
    + "\n                </a>\n\n                <div class=\"dropdown-menu\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.leasing_comm",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":53,"column":26},"end":{"line":53,"column":67}}}),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":20},"end":{"line":53,"column":165}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.pm_fee",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":54,"column":26},"end":{"line":54,"column":61}}}),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":20},"end":{"line":54,"column":135}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:cashflow",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":55,"column":26},"end":{"line":55,"column":55}}}),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":55,"column":135}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.unpaid_bills",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":56,"column":26},"end":{"line":56,"column":67}}}),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":147}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":57,"column":26},"end":{"line":57,"column":49}}}),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":20},"end":{"line":57,"column":135}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm.download",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":58,"column":26},"end":{"line":58,"column":58}}}),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":20},"end":{"line":58,"column":144}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm.download",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":59,"column":26},"end":{"line":59,"column":58}}}),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":59,"column":142}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.cancelled_rooms",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":60,"column":26},"end":{"line":60,"column":70}}}),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":20},"end":{"line":60,"column":162}}})) != null ? stack1 : "")
    + "\n                </div>\n            </li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/leasing-commission\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.leasing-commission",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":124},"end":{"line":53,"column":154}}}))
    + "</a>";
},"28":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/pm-fee\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.pm-fee",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":106},"end":{"line":54,"column":124}}}))
    + "</a>";
},"30":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/cashflows\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.cashflows",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":103},"end":{"line":55,"column":124}}}))
    + "</a>";
},"32":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/unpaid-bills\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.unpaid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":118},"end":{"line":56,"column":136}}}))
    + "</a>";
},"34":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/income-deals\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.income-deals",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":100},"end":{"line":57,"column":124}}}))
    + "</a>";
},"36":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/owner-downloads\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.owner-pmr",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":112},"end":{"line":58,"column":133}}}))
    + "</a>";
},"38":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/shop-downloads\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.shop-pmr",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":111},"end":{"line":59,"column":131}}}))
    + "</a>";
},"40":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#cs/cancelled-rooms\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.cancelled-rooms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":124},"end":{"line":60,"column":151}}}))
    + "</a>";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"nav-item dropdown\">\n                <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n                    "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":68,"column":40}}}))
    + "\n                </a>\n                <div class=\"dropdown-menu\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:shops",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":71,"column":26},"end":{"line":71,"column":52}}}),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":20},"end":{"line":71,"column":121}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:product_types",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":72,"column":26},"end":{"line":72,"column":60}}}),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":20},"end":{"line":72,"column":145}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:product_types",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":73,"column":26},"end":{"line":73,"column":60}}}),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":20},"end":{"line":73,"column":147}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":74,"column":26},"end":{"line":74,"column":45}}}),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":20},"end":{"line":74,"column":114}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":75,"column":26},"end":{"line":75,"column":45}}}),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":20},"end":{"line":75,"column":131}}})) != null ? stack1 : "")
    + "\n                </div>\n            </li>\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "<a class=\"dropdown-item\" href=\"#shops\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.shops",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":93},"end":{"line":71,"column":110}}}))
    + "</a>";
},"45":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#product-types\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.product-types",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":109},"end":{"line":72,"column":134}}}))
    + "</a>";
},"47":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#product-stocks\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.product-stocks",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":110},"end":{"line":73,"column":136}}}))
    + "</a>";
},"49":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#files\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":86},"end":{"line":74,"column":103}}}))
    + "</a>";
},"51":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#cs/zendesk-bulk\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.zendesk-bulk",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":96},"end":{"line":75,"column":120}}}))
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\">\n\n    <a class=\"navbar-brand\" href=\"#\">\n        <img alt=\"Consus\" src=\"/img/logo_trt_dark_2022.png\" width=\"200\">\n    </a>\n\n    <button type=\"button\" class=\"navbar-toggler\" data-toggle=\"collapse\" data-target=\"#nav\" aria-controls=\"navbarText\"\n        aria-expanded=\"false\" aria-label=\"Toggle navigation\">\n        <span class=\"navbar-toggler-icon\"></span>\n    </button>\n\n    <div id=\"nav\" class=\"collapse navbar-collapse\" aria-expanded=\"false\">\n        <ul class=\"navbar-nav ml-auto navbar-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:customers","consus:contracts","consus:product_contracts","admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":102}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports","consus:reports.leasing_status","consus:reports.room_occupancy","consus:reports.tenants","consus:reports.rental_discount","consus:reports.price_check",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":28,"column":18},"end":{"line":28,"column":197}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.leasing_comm","consus:reports.pm_fee","consus:cashflow","consus:reports.unpaid_bills","consus:pm","consus:pm.download","consus:reports.cancelled_rooms",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":46,"column":18},"end":{"line":46,"column":197}}}),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:shops","consus:product_types","admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":65,"column":18},"end":{"line":65,"column":75}}}),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":78,"column":19}}})) != null ? stack1 : "")
    + "\n            <li class=\"nav-item\"><a class=\"nav-link\" href=\"#logout\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.logout",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":68},"end":{"line":80,"column":86}}}))
    + "</a></li>\n        </ul>\n    </div>\n\n</div>";
},"useData":true});