var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form id=\"frmEditShop\" autocomplete=\"off\" novalidate>\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-name\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":95},"end":{"line":5,"column":112}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-name\" name=\"name\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-name_en\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name_en",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":75},"end":{"line":15,"column":95}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-name_en\" name=\"name_en\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-code\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":95},"end":{"line":26,"column":112}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-code\" name=\"code\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-owner_id\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":75},"end":{"line":38,"column":93}}}))
    + "</label>\n                <div id=\"divOwners\" class=\"col-md\"></div>\n            </div>\n        </div>\n\n        <div class=\"col-lg d-none\" data-part=\"status\">\n            <div class=\"form-group row\">\n                <label for=\"field-status\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":75},"end":{"line":46,"column":100}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <select id=\"field-status\" name=\"status\" class=\"form-control\">\n                        <option value=\"hidden\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.status.hidden",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":47},"end":{"line":49,"column":73}}}))
    + "</option>\n                        <option value=\"coming\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.status.coming",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":47},"end":{"line":50,"column":73}}}))
    + "</option>\n                        <option value=\"active\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.status.active",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":47},"end":{"line":51,"column":73}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-start-date\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.start_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":75},"end":{"line":62,"column":98}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-start-date\" name=\"start_date\" type=\"date\" class=\"form-control\" maxlength=\"8\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-initial-leased-rooms\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.initial_leased_rooms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":75},"end":{"line":72,"column":108}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-initial-leased-rooms\" name=\"initial_leased_rooms\" type=\"text\" class=\"form-control\"\n                        data-number>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-open-date\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.open_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":75},"end":{"line":85,"column":97}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-open-date\" name=\"open_date\" type=\"date\" class=\"form-control\" maxlength=\"8\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-payment_tech\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.payment_tech.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":75},"end":{"line":95,"column":106}}}))
    + "</label>\n                <div class=\"col-md-6\">\n                    <select id=\"field-payment_tech\" name=\"payment_tech\" class=\"form-control\">\n                        <option value=\"br\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.payment_tech.br",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":43},"end":{"line":98,"column":71}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-campaign_duration\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_duration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":109,"column":66},"end":{"line":109,"column":96}}}))
    + "</label>\n                <div class=\"col-md col-lg-3\">\n                    <div class=\"input-group\">\n                        <input id=\"field-campaign_duration\" name=\"campaign_duration\" type=\"text\"\n                            class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">月</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-campaign_eligible_min_months\"\n                    class=\"col-form-label col-md-3  col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.campaign_eligible_min_months",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":125,"column":76},"end":{"line":125,"column":117}}}))
    + "</label>\n                <div class=\"col-md col-lg-3\">\n                    <div class=\"input-group\">\n                        <input id=\"field-campaign_eligible_min_months\" name=\"campaign_eligible_min_months\" type=\"text\"\n                            class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">月</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.billing_robot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":32},"end":{"line":141,"column":58}}}))
    + "</p>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-br_user_id\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.br_user_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":147,"column":75},"end":{"line":147,"column":98}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-br_user_id\" name=\"br_user_id\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-br_access_key\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.br_access_key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":157,"column":75},"end":{"line":157,"column":101}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-br_access_key\" name=\"br_access_key\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-br_aid\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.br_aid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":168,"column":97},"end":{"line":168,"column":116}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <input id=\"field-br_aid\" name=\"br_aid\" type=\"text\" class=\"form-control\" maxlength=\"6\">\n                </div>\n            </div>\n        </div>\n        <div class=\"col-lg\">\n            <div class=\"row\">\n                <label for=\"field-br_aid\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.is_payment_system_ready",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":176,"column":97},"end":{"line":176,"column":133}}}))
    + "</label>\n                <div class=\"form-group col-md\">\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-is_payment_system_ready-0\" type=\"radio\" class=\"form-check-input\" name=\"is_payment_system_ready\" value=\"0\" data-number>\n                        <label for=\"field-is_payment_system_ready-0\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":181,"column":28},"end":{"line":181,"column":46}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-is_payment_system_ready-1\" type=\"radio\" class=\"form-check-input\" name=\"is_payment_system_ready\" value=\"1\" data-number>\n                        <label for=\"field-is_payment_system_ready-1\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.yes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":28},"end":{"line":187,"column":47}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">FREEE</p>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-freee_account_item_id\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">勘定科目</label>\n                <div id=\"divAcctItem\" class=\"col-md-9 col-lg-8\"></div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-freee_partner_id\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">取引先</label>\n                <div id=\"divPartner\" class=\"col-md-9 col-lg-8\"></div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":218,"column":32},"end":{"line":218,"column":52}}}))
    + "</p>\n\n    <div id=\"divAddress\"></div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-latitude\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.latitude",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":226,"column":75},"end":{"line":226,"column":96}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <input id=\"field-latitude\" name=\"latitude\" type=\"text\" class=\"form-control\" data-number>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-longitude\"\n                    class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.longitude",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":236,"column":75},"end":{"line":236,"column":97}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <input id=\"field-longitude\" name=\"longitude\" type=\"text\" class=\"form-control\" data-number>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">GIRAFFE</p>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-giraffe_facility_id\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_facility_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":252,"column":20},"end":{"line":252,"column":52}}}))
    + "\n                </label>\n                <div class=\"col-md\">\n                    <input id=\"field-giraffe_facility_id\" name=\"giraffe_facility_id\" type=\"text\" class=\"form-control\"\n                        readonly>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"row\">\n                <label class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_qr_code_access_status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":264,"column":20},"end":{"line":264,"column":68}}}))
    + "\n                </label>\n                <div class=\"form-group col-md\">\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-giraffe_qr_code_access_status-0\" type=\"radio\" class=\"form-check-input\"\n                            name=\"giraffe_qr_code_access_status\" value=\"0\" data-number>\n                        <label for=\"field-giraffe_qr_code_access_status-0\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_qr_code_access_status.0",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":271,"column":28},"end":{"line":271,"column":72}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-giraffe_qr_code_access_status-1\" type=\"radio\" class=\"form-check-input\"\n                            name=\"giraffe_qr_code_access_status\" value=\"1\" data-number>\n                        <label for=\"field-giraffe_qr_code_access_status-1\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_qr_code_access_status.1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":278,"column":28},"end":{"line":278,"column":72}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-giraffe_qr_code_access_status-2\" type=\"radio\" class=\"form-check-input\"\n                            name=\"giraffe_qr_code_access_status\" value=\"2\" data-number>\n                        <label for=\"field-giraffe_qr_code_access_status-2\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_qr_code_access_status.2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":285,"column":28},"end":{"line":285,"column":72}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">Campaign Monitor</p>\n\n    <div class=\"row\">\n        <div class=\"col-lg-8\">\n            <div class=\"form-group row\">\n                <label for=\"field-cm_list_id\"\n                    class=\"col-form-label col-md-3 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.campaign_monitor_list_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":301,"column":66},"end":{"line":301,"column":103}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-cm_list_id\" name=\"cm_list_id\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <div class=\"row\">\n        <div class=\"col-lg-8\">\n            <div class=\"form-group row\">\n                <label for=\"field-notes\" class=\"col-form-label col-md-3 col-lg-3 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.notes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":315,"column":20},"end":{"line":315,"column":38}}}))
    + "\n                </label>\n                <div class=\"col-md\">\n                    <textarea id=\"field-notes\" name=\"notes\" rows=\"10\" type=\"text\" class=\"form-control\"></textarea>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row justify-content-end align-items-center\">\n        <div class=\"col-md-auto\">\n            <button type=\"button\" class=\"btn btn-secondary btn-block\"\n                data-action=\"cancel\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":327,"column":37},"end":{"line":327,"column":59}}}))
    + "</button>\n        </div>\n        <div class=\"col-md-auto\">\n            <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":330,"column":68},"end":{"line":330,"column":88}}}))
    + "</button>\n        </div>\n    </div>\n</form>";
},"useData":true});