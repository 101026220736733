import { Collection } from 'backbone';
import config from '@/js/app/config';
import PartnerModel from '../models/partner';

export default class AccountItemsCollection extends Collection {
    preinitialize() {
        this.url = config.api.url + 'freee/partners/';

        // Reference to this collection's model.
        this.model = PartnerModel;
    }
}
