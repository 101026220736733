var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"generic.yes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":27}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"generic.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":26}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <dt class=\"col-3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":46}}}))
    + "</dt>\n    <dd class=\"col-3\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"productType") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</dd>\n\n    <dt class=\"col-3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.br_item_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":54}}}))
    + "</dt>\n    <dd class=\"col-3\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"productType") : depth0)) != null ? lookupProperty(stack1,"br_item_code") : stack1), depth0))
    + "</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.price",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":22},"end":{"line":10,"column":47}}}))
    + "</dt>\n    <dd class=\"col-3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"money.yen",{"name":"t","hash":{"value":__default(require("../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"productType") : depth0)) != null ? lookupProperty(stack1,"price") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":11,"column":44},"end":{"line":11,"column":78}}})},"data":data,"loc":{"start":{"line":11,"column":22},"end":{"line":11,"column":80}}}))
    + "</dd>\n\n    <dt class=\"col-3\">Free days</dt>\n    <dd class=\"col-3\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"productType") : depth0)) != null ? lookupProperty(stack1,"free_days") : stack1), depth0))
    + "日間</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.is_intangible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":22},"end":{"line":18,"column":55}}}))
    + "</dt>\n    <dd class=\"col-3\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"productType") : depth0)) != null ? lookupProperty(stack1,"is_intangible") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "    </dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.notes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":25},"end":{"line":29,"column":50}}}))
    + "</dt>\n    <dd class=\"col-md-10\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"productType") : depth0)) != null ? lookupProperty(stack1,"notes") : stack1), depth0))
    + "</dd>\n</div>";
},"useData":true});