import AddressView from '@/js/app/address/views/address';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import OwnerCntrView from '@/js/app/owners/views/cntr';
import User from '@/js/app/user';
import yuyan from '@/js/app/yuyan';
import formToObject from '@/js/libs/form-utils';
import AccountItemsCollection from '../../freee/account-items/collections/account-items';
import AccountItemCntrView from '../../freee/account-items/views/cntr';
import PartnersCollection from '../../freee/partners/collections/partners';
import PartnerCntrView from '../../freee/partners/views/cntr';
import updateTemplate from '../templates/edit.hbs';
import readTemplate from '../templates/read.hbs';

export default class ShopReadUpdateView extends CardView {
    preinitialize() {
        // Initialize defaults
        this.title = yuyan.t('shop.details');

        this.events = {
            'click [data-action="edit"]': this.renderEdit,
            'click [data-action="cancel"]': this.renderRead,
            'click [data-action="advance"]': this.advanceStatus,
            'submit form#frmEditShop': this.saveUpdate,
        };

        this.buttons = [
            {
                cntrData: 'advanceBtnCntr',
                text: '',
                className: 'btn-primary',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'advance'
                    }
                ]
            }
        ];

        // If user has shops permission
        if (User.isAllowed('consus:shops')) {
            // Include edit and cancel buttons
            this.buttons.push({
                cntrData: 'editBtnCntr',
                text: yuyan.t('generic.edit'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'edit'
                    }
                ]
            }, {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            });
        }
    }

    initialize() {
        this.subviews = {
            address: new AddressView(),
            ownerCntrView: new OwnerCntrView({}),
            acctItemCntrView: new AccountItemCntrView({
                collection: new AccountItemsCollection({
                    group_id: 611656580,    // 立替金
                }),
                field_id: 'field-freee_account_item_id',
                field_name: 'freee_account_item_id'
            }),
            partnerCntrView: new PartnerCntrView({
                collection: new PartnersCollection(),
                field_id: 'field-freee_partner_id',
                field_name: 'freee_partner_id'
            }),
        };

        const s = new SpinnerView();
        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            s.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            s.stop();
        });

        // When model changes; render
        this.listenTo(this.model, 'change', this.renderRead);
    }

    render() {
        console.debug('ShopReadUpdate#render');

        this.renderCard();

        return this;
    }

    renderRead() {
        console.debug('ShopReadUpdate#renderRead');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('editBtnCntr')
            .changeAdvanceButton(this.model.get('status'))
            .renderReadTemplate();

        return this;
    }

    renderReadTemplate() {
        console.debug('ShopReadUpdate#renderReadTemplate');

        // Attach readTemplate to el with shop model
        this.cardBody.innerHTML = readTemplate({
            shop: this.model.toJSON(),
        });

        return this;
    }

    renderEdit() {
        console.debug('ShopReadUpdate#renderEdit');

        this.hideButtonContainer('editBtnCntr')
            .hideButtonContainer('advanceBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderEditTemplate();

        return this;
    }

    renderEditTemplate() {
        console.debug('ShopReadUpdate#renderEditTemplate');

        // Attach updateTemplate to el
        this.cardBody.innerHTML = updateTemplate();

        this.el.querySelector('[data-part="status"]').classList.remove('d-none');

        // Set el of address view as #divAddress then render and set value
        this.subviews.address.setElement(this.el.querySelector('#divAddress')).render()
            .setValue(this.model.get('prefecture_id'), this.model.get('city_id'));

        // Set el of owner cntr view as #divOwners, render, set value then fetch collection
        this.subviews.ownerCntrView.setElement(this.el.querySelector('#divOwners')).render()
            .setValue(this.model.get('owner_id')).collection.fetch();

        // Set el of account items view as #divAcctItem then render
        this.subviews.acctItemCntrView.setElement(this.el.querySelector('#divAcctItem')).render()
            .setValue(this.model.get('freee_account_item_id'));

        // Trigger fetch of Freee account item collection
        this.subviews.acctItemCntrView.collection.fetch();

        // Set el of account items view as #divPartner then render
        this.subviews.partnerCntrView.setElement(this.el.querySelector('#divPartner')).render()
            .setValue(this.model.get('freee_partner_id'));

        // Trigger fetch of Freee partner collection
        this.subviews.partnerCntrView.collection.fetch();

        // If model exists
        if (this.model) {
            // For each model attribute
            _.each(this.model.toJSON(), (val, key) => {
                // If value is array
                if (Array.isArray(val)) {
                    // For each value in array
                    val.forEach(v => {
                        // Initialize field as element with name = key
                        const field = this.el.querySelector('[name="' + key + '"][value="' + v + '"]');

                        // If field exists, set as checked
                        if (field) {
                            field.checked = true;
                        }
                    });
                } else if (key !== 'giraffe_qr_code_access_status' && key !== 'is_payment_system_ready') {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector('[name="' + key + '"]');

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                }
            });

            // Disable giraffe access status if facility id doesn't exist
            if (!this.model.get('giraffe_facility_id')) {
                this.el.querySelectorAll('input[name="giraffe_qr_code_access_status"]').forEach(el => {
                    el.disabled = true;
                })
            }

            // Only preset radio button if giraffe access status is not null
            if (this.model.get('giraffe_qr_code_access_status') !== null) {
                this.el.querySelector('input[name="giraffe_qr_code_access_status"][value="' + this.model.get('giraffe_qr_code_access_status') + '"]').checked = true;
            }

            // Only preset radio button if is_payment_system_ready is not null
            if (this.model.get('is_payment_system_ready') !== null) {
                this.el.querySelector('input[name="is_payment_system_ready"][value="' + this.model.get('is_payment_system_ready') + '"]').checked = true;
            }
        }

        return this;
    }

    advanceStatus() {
        console.debug('ShopReadUpdate#advanceStatus');

        if (confirm("Are you sure?")) {
            let status = '';
            switch (this.model.get('status')) {
                case 'hidden':
                    status = 'active';
                    break;
            }

            // Trigger save of model with changes as attributes
            this.model.save({ status: status }, {
                patch: true,
                wait: true,
                // On error: errorOnSave
                error: this.errorOnSave
            });
        }
    }

    changeAdvanceButton(status) {
        console.debug('ShopReadUpdate#changeAdvanceButton');

        this.el.querySelector('[data-action="advance"]').innerText = yuyan.t('shop.status.action.' + status);
        if (status === 'active') {
            this.hideButtonContainer('advanceBtnCntr')
        } else {
            this.showButtonContainer('advanceBtnCntr')
        }

        return this;
    }

    saveUpdate(e) {
        console.debug('ShopReadUpdate#saveUpdate');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            if (e.currentTarget.checkValidity() === false) {
                // If form validity is false return
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Initialize changes as result of changedAttributes of model based on data
            const changes = this.model.changedAttributes(data);

            // If changes exist
            if (changes !== false) {
                // Trigger save of model with changes as attributes
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    // On error: errorOnSave
                    error: this.errorOnSave
                });
            } else {
                // Else trigger change of model
                this.model.trigger('change', this.model);
            }
        }
    }

    errorOnSave(model, response) {
        console.debug('ShopReadUpdate#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
