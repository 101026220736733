import { Model } from 'backbone';
import config from '@/js/app/config';

export default class AccountItemModel extends Model {
    preinitialize(options) {
        this.groupId = options.groupId;

        this.urlRoot = config.api.url + 'freee/account_items/' + this.groupId;
    }
}
