import CardView from '@/js/app/generic/views/card';
import oauth2Client from '@/js/app/oauth2-client';
import ShopModel from '@/js/app/shop/models/shop';
import yuyan from '@/js/app/yuyan';
import dayjs from 'dayjs';
import ShopReportCollection from '../../collections/shop-reports';
import template from '../../templates/shop-downloads/item-container.hbs';
import PmShopDownloadsCardItemView from './card-item';
import PmShopDownloadsCardItemNoCollectionView from './card-item-no-collection';

// Report types

/**
 * Export only report type
 */
const EXPORT = 'EXPORT';

/**
 * Previous upload turned export report type
 */
const EXPORT_LEGACY_UPLOAD = 'EXPORT_LEGACY_UPLOAD';

/**
 * Download with history report type
 */
// const DOWNLOAD = 'DOWNLOAD';

/**
 * Generate and download with history report type
 */
const SNAPSHOT = 'SNAPSHOT';

/**
 * Upload and download with history report type
 */
const UPLOAD = 'UPLOAD';

export default class PmShopDownloadsCardView extends CardView {
    preinitialize(options) {
        this.collection = new ShopReportCollection();

        this.model = new ShopModel();

        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: '<i class="fa fa-download" aria-hidden="true"></i> ' + yuyan.t('generic.download'),
                className: 'btn-success',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'download'
                    }
                ]
            },
        ];

        this.events = {
            'click [data-action="download"]': this.handleClickDownload,
        }

        this.reports = [
            {
                type: {
                    label: 'Leasing Commission',
                    value: 'leasing_commission'
                },
                cardItemType: EXPORT,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'PM Fee',
                    value: 'pm_fee'
                },
                cardItemType: EXPORT,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'Monthly',
                    value: 'monthly'
                },
                cardItemType: EXPORT,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'Accounts Receivable',
                    value: 'accounts_receivable'
                },
                cardItemType: EXPORT_LEGACY_UPLOAD,
                // cardItemType: SNAPSHOT,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'Rent Roll',
                    value: 'rent_roll'
                },
                cardItemType: EXPORT_LEGACY_UPLOAD,
                // cardItemType: SNAPSHOT,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'Historical Cashflow',
                    value: 'historical_cashflow'
                },
                cardItemType: EXPORT,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'Monthly Cashflow',
                    value: 'monthly_cashflow'
                },
                cardItemType: EXPORT_LEGACY_UPLOAD,
                // cardItemType: SNAPSHOT,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'Historical Acc',
                    value: 'historical_acc'
                },
                cardItemType: SNAPSHOT
            },
            {
                type: {
                    label: 'Funding Request',
                    value: 'funding_request'
                },
                cardItemType: EXPORT_LEGACY_UPLOAD,
                // cardItemType: UPLOAD,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'Marketing Plan',
                    value: 'marketing_plan'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: 'Building Management',
                    value: 'building_management'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: 'Periodic Inspection',
                    value: 'periodic_inspection'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: 'Inspection Notification',
                    value: 'inspection_notification'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: 'Capex & Repair',
                    value: 'capex_repair'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: '証憑',
                    value: 'evidence'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: '口座振替売上明細',
                    value: 'direct_debit_sales_statement'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: 'クレジット売上明細',
                    value: 'credit_sales_statement'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: 'あんしん保証パック',
                    value: 'anshin_pack_evidence'
                },
                cardItemType: EXPORT,
                fileExtension: 'xlsx'
            },
        ];

        this.criteria = options.criteria;

        this.previousViews = [];
    }

    initialize() {
        this.listenTo(this.criteria, 'change', this.handleCriteriaChange);
        this.listenTo(this.model, 'change', this.handleModelChange);
        this.listenTo(this.collection, 'reset', this.handleCollectionReset)
    }

    render() {
        console.debug('PmShopDownloadsCard#render');

        this.renderCard();

        this.cardBody.innerHTML = template();

        return this;
    }

    handleCriteriaChange(changedCriteria) {
        console.debug('PmShopDownloadsCard#handleCriteriaChange');

        if (changedCriteria.shopId !== undefined) {
            this.model.set('id', changedCriteria.shopId, { silent: true });
            if (changedCriteria.shopId) {
                this.trigger('request');

                this.model.fetch();
            } else {
                while (this.previousViews.length > 0) {
                    const view = this.previousViews.pop();
                    view.remove();
                }

                this.el.removeChild(this.el.firstChild);
            }
        } else if (changedCriteria.period) {
            this.trigger('request');

            this.collection.fetch({
                data: {
                    period: changedCriteria.period
                },
                reset: true
            });
        }
    }

    handleModelChange() {
        console.debug('PmShopDownloadsCard#handleModelChange');

        // Set title of card
        this.title = yuyan.t('pm.shop-downloads.list', { shopName: this.model.get('name') });

        this.render();

        this.collection.shopId = this.model.id;
        this.collection.fetch({
            data: {
                period: this.criteria.period
            },
            reset: true
        });
    }

    handleCollectionReset() {
        console.debug('PmShopDownloadsCard#handleCollectionReset');

        this.trigger('sync');

        const el = this.el.querySelector('[data-slot="items"]');

        while (this.previousViews.length > 0) {
            const view = this.previousViews.pop();
            view.remove();
        }

        const cardItemListFragment = document.createDocumentFragment();

        this.reports.forEach(report => {
            if (report.cardItemType === EXPORT) {
                const view = new PmShopDownloadsCardItemNoCollectionView({
                    model: this.model,
                    type: report.type,
                    criteria: this.criteria,
                    fileExtension: report.fileExtension
                });
                this.previousViews.push(view);

                cardItemListFragment.appendChild(view.render().el);
            } else {
                const viewOptions = {
                    model: this.model,
                    type: report.type,
                    collection: new ShopReportCollection(this.collection.filter(model => model.get('type') === report.type.value), { shopId: this.collection.shopId }),
                    criteria: this.criteria,
                    fileExtension: report.fileExtension,
                    hasUpload: false,
                    hasGenerate: false,
                    hasDownload: false
                };

                if (report.cardItemType === UPLOAD) {
                    viewOptions.hasUpload = true;
                } else if (report.cardItemType === SNAPSHOT) {
                    viewOptions.hasGenerate = true;
                } else if (report.cardItemType === EXPORT_LEGACY_UPLOAD) {
                    viewOptions.hasDownload = true;
                }

                const view = new PmShopDownloadsCardItemView(viewOptions);
                this.previousViews.push(view);

                cardItemListFragment.appendChild(view.render().el);
            }
        });

        el.appendChild(cardItemListFragment);

        return this;
    }

    handleClickDownload() {
        console.debug('PmShopDownloadsCard#handleClickDownload');

        this.#downloadAll();
    }

    #downloadAll() {
        const version = dayjs().format('YYYYMMDDHHmmss');

        // Download specific version of uploaded file
        oauth2Client.download(
            this.collection.url() + '/' + this.criteria.period,
            {
                method: 'GET'
            },
            {
                filename: `${this.model.get('name')}_${this.criteria.period}_v${version}.xlsx`
            }
        );
    }
}
