import { View, Collection } from 'backbone';
import SpinnerView from '@/js/app/generic/views/spinner';
import AcctItemView from './index';

export default class AccountItemCntrView extends View {
    initialize(options) {
        // Create subviews
        this.subviews = {
            acctItems: new AcctItemView({
                collection: this.collection,
                id: options.field_id,
                attributes: {
                    name: options.field_name,
                    'data-number': ''
                }
            })
        };

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('AccountItemCntr#render');

        // Attach acctItems view to el
        while (this.el.firstChild) {
            this.el.removeChild(this.el.firstChild);
        }
        this.el.appendChild(this.subviews.acctItems.el);

        return this;
    }

    setValue(value) {
        console.debug('AccountItemCntr#setValue');

        this.subviews.acctItems.setValue(value);
    }
}
