import dayjs from 'dayjs';
import formToObject from '@/js/libs/form-utils';
import config from '@/js/app/config';
import CustomerNamePickerView from '@/js/app/customer/views/name-picker';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import oauth2Client from '@/js/app/oauth2-client';
import User from '@/js/app/user';
import yuyan from '@/js/app/yuyan';
import alertTemplate from '../templates/alert.hbs';
import updateTemplate from '../templates/edit.hbs';
import cancelProgressTemplate from '../templates/progress-cancel.hbs';
import withdrawProgressTemplate from '../templates/progress-withdraw.hbs';
import progressTemplate from '../templates/progress.hbs';
import progressCcTemplate from '../templates/progress_cc.hbs';
import readTemplate from '../templates/read.hbs';
import ContractCancellationModalView from './cancellation-modal';
import UnitView from './unit';

export default class ContractReadUpdateView extends CardView {
    preinitialize() {
        // Initialize defaults
        this.title = yuyan.t('contract.details');

        this.events = {
            'click [data-action="edit"]': this.renderEdit,
            'click [data-action="cancel"]': this.renderRead,
            'click [data-action="advance"]': this.advanceStatus,
            'click [data-action="withdraw"]': this.withdrawContract,
            'click [data-action="end"]': this.handleCancelContractClick,
            'click [data-action="migrate"]': this.migrateContract,
            'change [name="start_date"]': this.handleStartDateChange,
            'change [name="payment_method"]': this.handlePaymentMethodChange,
            'change [name="campaign_duration"]': this.handleCampaignDurationChange,
            'change [data-rate="flat"]': this.handleFlatRateChange,
            'click #btnGetCosts': this.autoFillRates,
            'change [data-part="initialSelect"]': this.handleSurveyInitialSelectChange,
            'submit form#frmEditContract': this.saveUpdate,
        };

        this.buttons = [
            {
                cntrData: 'migrateBtnCntr',
                text: yuyan.t('contract.action.migrate'),
                className: 'btn-primary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'migrate'
                    }
                ]
            },
            {
                cntrData: 'withdrawBtnCntr',
                text: yuyan.t('contract.action.withdraw'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'withdraw'
                    }
                ]
            },
            {
                cntrData: 'endBtnCntr',
                text: yuyan.t('contract.action.cancel'),
                className: 'btn-danger',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'end'
                    }
                ]
            },
            {
                cntrData: 'advanceBtnCntr',
                text: '',
                className: 'btn-primary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'advance'
                    }
                ]
            },
            {
                cntrData: 'editBtnCntr',
                text: yuyan.t('generic.edit'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'edit'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];
    }

    initialize() {
        this.subviews = {
            namePicker: new CustomerNamePickerView({
                contractModel: this.model
            }),

            unit: new UnitView()
        };

        const s = new SpinnerView();
        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            s.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            s.stop();
        });

        // When collection updates/resets; render
        this.listenTo(this.model, 'change', this.renderRead);
    }

    render() {
        console.debug('ContractReadUpdate#render');

        this.renderCard();

        return this;
    }

    renderRead() {
        console.debug('ContractReadUpdate#renderRead');

        const status = this.model.get('status');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('editBtnCntr')
            .changeAdvanceButton(status)
            .changeEndButton(status)
            .changeWithdrawButton(status)
            .changeMigrateButton(this.model)
            .renderReadTemplate();

        return this;
    }

    renderReadTemplate() {
        console.debug('ContractReadUpdate#renderReadTemplate');

        const isCancellation = this.isCancellation();

        // Attach readTemplate to el with contract model
        this.cardBody.innerHTML = readTemplate({
            contract: this.model.toJSON(),
            isCancellation
        });

        // If payment method is credit, show .credit-details
        if (this.model.get('payment_method') === 'credit') {
            this.$el.find('[data-part="credit-details"]').removeClass('d-none');
        }
        // Else if payment method is direct_debit
        else if (this.model.get('payment_method') === 'direct_debit') {
            if (User.isAllowed('consus:contracts.payment') || User.isAllowed('consus:contracts.payment:read')) {
                // Show bank details
                this.$el.find('[data-part="bank-details"]').removeClass('d-none');

                if (this.model.get('payment_tech') === 'br') {
                    this.$el.find('[data-part="rp-customer-number"]').removeClass('d-none');
                }
            }

            // Show bank transfer fees
            this.$el.find('[data-part="directDebitFee"]').removeClass('d-none');
        }

        const status = this.model.get('status');
        if (status) {
            if (['pending', 'approved_us', 'documents_sent', 'applied', 'approved_bank', 'initial_payment_confirmed', 'keys_sent', 'active'].includes(status)) {
                if (this.model.get('payment_method') === 'credit') {
                    this.el.querySelector('[data-part="progress"]').innerHTML = progressCcTemplate();
                } else {
                    this.el.querySelector('[data-part="progress"]').innerHTML = progressTemplate();
                }
            } else if (['withdraw_requested', 'withdrawn'].includes(status)) {
                this.el.querySelector('[data-part="progress"]').innerHTML = withdrawProgressTemplate();
            } else if (isCancellation) {
                this.el.querySelector('[data-part="progress"]').innerHTML = cancelProgressTemplate({
                    isShortContract: this.model.get('cancellation_fee')
                });
            }

            this.renderProgress();
        }

        let alerts = this.model.get('alert');
        if (alerts.length) {
            let elements = '';
            for (const alert of alerts) {
                let type = 'danger';
                switch (alert) {
                    case 'document':
                        type = 'warning';
                        break;
                    case 'duration':
                        type = 'warning';
                        break;
                    case 'fee_warning':
                        type = 'warning';
                        break;
                }

                elements += alertTemplate({
                    type: type,
                    title: yuyan.t(`contract.alert.${alert}`),
                    text: yuyan.t(`contract.alert.${alert}_long`)
                });
            }

            this.el.querySelector('[data-part="alert"]').innerHTML = elements;
        }

        this.initialMonths = this.model.get('initial_months');
        this.previousInitialMonths = this.model.get('initial_months');
        this.campaignDuration = this.model.get('campaign_duration');

        return this;
    }

    renderProgress() {
        console.debug('ContractReadUpdate#renderProgress');

        const progressElement = this.el.querySelector('[data-progress="' + this.model.get('status') + '"]');
        if (progressElement) {
            progressElement.classList.add('status-contract-' + this.model.get('status'));
        }
    }

    isCancellation() {
        return [
            'cancel_applied',
            'billing_stopped',
            'cancel_short_settled',
            'cancel_settled',
            'keys_returned',
            'cancelled'
        ].includes(this.model.get('status'));
    }

    advanceStatus() {
        console.debug('ContractReadUpdate#advanceStatus');

        if (confirm("Are you sure?")) {
            let status = '';
            switch (this.model.get('status')) {
                case 'pending':
                    status = 'approved_us';
                    break;
                case 'approved_us':
                    status = 'documents_sent';
                    break;
                case 'documents_sent':
                    status = 'applied';
                    break;
                case 'applied':
                    status = 'approved_bank';
                    break;
                case 'approved_bank':
                    if (this.model.get('payment_method') === 'credit') {
                        status = 'keys_sent';
                    } else {
                        status = 'initial_payment_confirmed';
                    }
                    break;
                case 'initial_payment_confirmed':
                    status = 'keys_sent';
                    break;
                case 'keys_sent':
                    status = 'active';
                    break;
                case 'withdraw_requested':
                    status = 'withdrawn';
                    break;
                case 'billing_stopped':
                    if (this.model.get('cancellation_fee')) {
                        status = 'cancel_short_settled'
                    } else {
                        status = 'cancel_settled';
                    }
                    break;
                case 'cancel_short_settled':
                    status = 'cancel_settled';
                    break;
                case 'cancel_settled':
                    status = 'keys_returned';
                    break;
                case 'keys_returned':
                    status = 'cancelled';
                    break;
            }

            // Trigger save of model with changes as attributes
            this.model.save({ status }, {
                patch: true,
                wait: true,
                error: this.errorOnSave
            });
        }
    }

    changeAdvanceButton(status) {
        console.debug('ContractReadUpdate#changeAdvanceButton');

        const advanceButton = this.el.querySelector('[data-action="advance"]');
        if (['withdrawn', 'active', 'cancel_applied', 'cancelled'].includes(status)) {
            advanceButton.innerText = '';
            this.hideButtonContainer('advanceBtnCntr');
        } else {
            advanceButton.innerText = yuyan.t('contract.status.' + this.model.get('status_next') + '.title');
            this.showButtonContainer('advanceBtnCntr');
        }

        return this;
    }

    changeEndButton(status) {
        console.debug('ContractReadUpdate#changeEndButton');

        if (['keys_sent', 'active'].includes(status)) {
            this.showButtonContainer('endBtnCntr');
        } else {
            this.hideButtonContainer('endBtnCntr');
        }

        return this;
    }

    changeWithdrawButton(status) {
        console.debug('ContractReadUpdate#changeWithdrawButton');

        if (['pending', 'documents_sent', 'applied', 'approved_us', 'approved_bank'].includes(status)) {
            this.showButtonContainer('withdrawBtnCntr');
        } else {
            this.hideButtonContainer('withdrawBtnCntr');
        }

        return this;
    }

    changeMigrateButton(model) {
        console.debug('ContractReadUpdate#changeMigrateButton');

        if (
            (model.get('room').shop.is_migration_ready || model.get('is_migration_ready')) && !model.get('is_migrated')
            && model.get('billing_info_sent') && model.get('payment_method') !== 'credit'
        ) {
            this.showButtonContainer('migrateBtnCntr');
        } else {
            this.hideButtonContainer('migrateBtnCntr');
        }

        return this;
    }

    withdrawContract() {
        console.debug('ContractReadUpdate#withdrawContract');

        if (confirm("Are you sure?")) {
            // Trigger save of model with changes as attributes
            this.model.save({ status: 'withdraw_requested' }, {
                patch: true,
                wait: true
            });
        }
    }

    handleCancelContractClick() {
        console.debug('ContractReadUpdate#handleCancelContractClick');

        const view = new ContractCancellationModalView({
            model: this.model
        });

        view.render();
    }

    migrateContract() {
        console.debug('ContractReadUpdate#migrateContract');

        if (confirm("Are you sure you want to migrate this contract?")) {
            // Trigger save of model with changes as attributes
            this.model.save({ is_migrated: 1 }, {
                patch: true,
                wait: true,
                // On error: errorOnSave
                error: this.errorOnSave
            });
        }
    }

    renderEdit() {
        console.debug('ContractReadUpdate#renderEdit');

        this.hideButtonContainer('editBtnCntr')
            .hideButtonContainer('advanceBtnCntr')
            .hideButtonContainer('withdrawBtnCntr')
            .hideButtonContainer('endBtnCntr')
            .hideButtonContainer('migrateBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderEditTemplate();

        return this;
    }

    renderEditTemplate() {
        console.debug('ContractReadUpdate#renderEditTemplate');

        // Attach updateTemplate to el
        this.cardBody.innerHTML = updateTemplate({
            isCancellation: this.isCancellation()
        });

        // Set el of namePicker view as #divCustomer then render and renderName
        this.subviews.namePicker.setElement(this.el.querySelector('#divCustomer')).render().renderName();

        // Set el of unit view as #divUnit then render and set value
        this.subviews.unit.setElement(this.el.querySelector('#divUnit')).render()
            .setValue(this.model.get('room').shop_id, this.model.get('room_id'));

        // If model exists
        if (this.model) {
            // For each model attribute
            _.each(this.model.toJSON(), (val, key) => {
                // If key is tags
                if (key === 'tags') {
                    // If tags is not array, skip
                    if (!Array.isArray(val)) {
                        return;
                    }

                    val.forEach(v => {
                        const field = this.el.querySelector(`[name="${key}"][value="${v}"]`);

                        // If field exists, set as checked
                        if (field) {
                            field.checked = true;
                        }
                    });
                }
                // Else if key is not 'existing' and not 'payment_method'
                else if (key !== 'existing' && key !== 'payment_method') {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector(`[name="${key}"]`);

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                }
            });

            this.el.querySelector('input[name="existing"][value="' + this.model.get('existing') + '"]').checked = true;

            this.el.querySelector('#field-deadline').value = this.model.get('document_deadline');

            const paymentMethod = this.model.get('payment_method');
            const status = this.model.get('status');

            // Set the checked value of payment_method element to true
            if (paymentMethod) {
                this.el.querySelector(`input[name="payment_method"][value="${paymentMethod}"]`).checked = true;
                this.preparePaymentDetails(paymentMethod);
            }

            if (status === 'pending') {
                this.$el.find('[data-part="longContract"]').addClass('d-none');
            }

            // If contact status after the point where we create invoices
            if (((paymentMethod === 'money_transfer' || paymentMethod === 'direct_debit') && !['pending', 'approved_us', 'documents_sent', 'applied'].includes(status)) ||
                (paymentMethod === 'credit' && !['applied'].includes(status))) {
                // Disable shop and unit select fields
                this.$el.find('#divUnit select').prop('disabled', true);
            }

            if (!['pending', 'approved_us', 'documents_sent', 'applied', 'approved_bank', 'keys_sent', 'active', 'cancel_applied'].includes(status)) {
                // Disable button and fields in 金額情報 section
                this.el.querySelector('#btnGetCosts').disabled = true;
                this.$el.find('[data-part="rates"] input').prop('readonly', true);
            }

            // If billing info sent already
            if (this.model.get('billing_info_sent')) {
                this.el.querySelectorAll('[name="payment_method"]').forEach(el => el.disabled = true);

                // If user can access contract payment info
                if (User.isAllowed('consus:contracts.payment:write')) {
                    // Enable current payment method
                    this.el.querySelector('[name="payment_method"][value="' + paymentMethod + '"]').disabled = false;

                    const room = this.model.get('room');
                    if (room.shop.payment_tech === 'br' || room.shop.is_migration_ready || this.model.get('is_migration_ready')) {
                        // Enable money transfer and direct debit
                        this.el.querySelector('[name="payment_method"][value="money_transfer"]').disabled = false;
                        this.el.querySelector('[name="payment_method"][value="direct_debit"]').disabled = false;
                    }
                }
            }
        }

        return this;
    }

    setFees(fees) {
        console.debug('ContractReadUpdate#setFees');

        this.defaultFees = fees;
    }

    handleStartDateChange(e) {
        console.debug('ContractReadUpdate#handleStartDateChange');

        if (e instanceof Object) {
            if (e.currentTarget.id === 'field-start_date') {
                // Set deadline to 7 days before start date
                this.el.querySelector('#field-deadline').value = dayjs(e.currentTarget.value).subtract(7, 'day').format('YYYY-MM-DD');

                // Set payment due date to 3 days before start date
                this.el.querySelector('#field-payment_due_date').value = dayjs(e.currentTarget.value).subtract(3, 'day').format('YYYY-MM-DD');

                const baseRate = this.el.querySelector('[name="base_rate"]').value;
                if (baseRate !== '') {
                    this.changeInitialCost();
                }
            }
        }
    }

    handlePaymentMethodChange(e) {
        console.debug('ContractReadUpdate#handlePaymentMethodChange');

        if (e instanceof Object) {
            this.preparePaymentDetails(e.currentTarget.value, true);
        }
    }

    preparePaymentDetails(paymentMethod, trigger) {
        console.debug('ContractReadUpdate#preparePaymentDetails');

        let bankFee = 0;
        // If payment method is direct_debit
        if (paymentMethod === 'direct_debit') {
            if (User.isAllowed('consus:contracts.payment:write')) {
                // Show and enable bank details
                this.el.querySelector('[data-part="bank-details"]').classList.remove('d-none');
                this.$el.find('.payment-detail').prop('readonly', false).prop('disabled', false);
                if (this.model.get('payment_tech') === 'br') {
                    this.$el.find('[data-part="rp-customer-number"]').removeClass('d-none');
                }
            }

            // Show bank fee
            bankFee = this.model.get('bank_fee') ? this.model.get('bank_fee') : this.defaultFees.bankFee;
            this.$el.find('[data-part="directDebitFee"]').removeClass('d-none');
        }
        // Else if payment method is credit or money_transfer
        else {
            // Hide and disable bank details
            this.el.querySelector('[data-part="bank-details"]').classList.add('d-none');
            this.$el.find('.payment-detail').prop('readonly', true).prop('disabled', true);

            // Hide bank fee
            this.$el.find('[data-part="directDebitFee"]').addClass('d-none');
        }

        if (paymentMethod === 'credit') {
            // Show payment tech
            this.el.querySelector('[data-part="payment-tech"]').classList.remove('d-none');
        } else {
            // Hide payment tech
            this.el.querySelector('[data-part="payment-tech"]').classList.add('d-none');
        }

        if (trigger) {
            this.$el.find('[name="bank_fee"]').val(bankFee).trigger('change');

            this.initialMonths = 3;
        }
    }

    handleCampaignDurationChange(e) {
        console.debug('ContractReadUpdate#handleCampaignDurationChange');

        if (e instanceof Object) {
            this.campaignDuration = Number(e.currentTarget.value);

            if (isNaN(this.campaignDuration)) {
                return;
            }

            this.changeInitialCost();
        }
    }

    changeInitialCost() {
        console.debug('ContractReadUpdate#changeInitialCost');

        let baseRate = Number(this.el.querySelector('[name="base_rate"]').value);
        let campaignRate = Number(this.el.querySelector('[name="campaign_rate"]').value);
        if (this.campaignDuration === 0 && !['active', 'cancel_applied'].includes(this.model.get('status'))) {
            this.changeFirstMonth(baseRate);
        } else if (this.campaignDuration >= 1) {
            this.changeFirstMonth(campaignRate);
        }
    }

    changeFirstMonth(rate) {
        console.debug('ContractReadUpdate#changeFirstMonth');

        const processedStartDate = new Date(this.el.querySelector('[name="start_date"]').value);
        const daysOfMonth = new Date(processedStartDate.getFullYear(), processedStartDate.getMonth() + 1, 0).getDate();
        const remainingDays = daysOfMonth - processedStartDate.getDate() + 1;

        let firstMonth = Math.round((rate / daysOfMonth) * remainingDays);

        this.$el.find('[name="first_month"]').val(firstMonth).trigger('change');
    }

    handleFlatRateChange(e) {
        console.debug('ContractReadUpdate#handleFlatRateChange');

        if (e instanceof Object) {
            this.changeInitialCost();
        }
    }

    async autoFillRates() {
        console.debug('ContractReadUpdate#autoFillRates');

        const roomModel = this.subviews.unit.model;
        this.campaignDuration = roomModel.get('campaign_duration') != null ?
            roomModel.get('campaign_duration') :
            roomModel.get('shop').campaign_duration;

        const params = {
            start_date: this.el.querySelector('[name="start_date"]').value,
            base_rate: roomModel.get('base_rate'),
            campaign_rate: roomModel.get('campaign_rate'),
            campaign_duration: this.campaignDuration,
            payment_method: this.el.querySelector('[name="payment_method"]:checked').value,
            initial_months: this.initialMonths
        };

        const url = new URL(config.api.url + 'contracts/costs');
        url.search = new URLSearchParams(params);

        const response = await oauth2Client.fetch(url, {});
        const responseData = await response.json();

        if (response.ok) {
            _.each(responseData, (val, key) => {
                if (key !== 'start_date' && key !== 'payment_method') {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector(`[name="${key}"]`);

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                }
            });

            const campaignEligibleMinMonths = roomModel.get('campaign_eligible_min_months') ?? roomModel.get('shop').campaign_eligible_min_months;
            const minUsageMonths = responseData.payment_method === 'credit' ? 2 : 3;

            this.$el.find('[name="campaign_eligible_min_months"]').val(campaignEligibleMinMonths >= minUsageMonths ? campaignEligibleMinMonths : minUsageMonths).trigger('change');
        } else {
            Promise.reject(responseData);
        }
    }

    handleSurveyInitialSelectChange(e) {
        console.debug('ContractReadUpdate#handleSurveyInitialSelectChange');

        const element = e.currentTarget;

        if (element.value === 'other') {
            this.$el.find('[data-part="surveyInitialSourceComment"]').prop('disabled', false).removeClass('d-none');
        } else {
            this.$el.find('[data-part="surveyInitialSourceComment"]').prop('disabled', true).addClass('d-none');
        }
    }

    saveUpdate(e) {
        console.debug('ContractReadUpdate#saveUpdate');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);
            data.initial_months = this.initialMonths;

            delete data.shop_id;

            // Initialize changes as result of changedAttributes of model based on data
            const changes = this.model.changedAttributes(data);

            // If changes exist
            if (changes !== false) {
                // Trigger save of model with changes as attributes
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    // On error: errorOnSave
                    error: this.errorOnSave
                });
            }
            // Else, trigger change of model
            else {
                this.model.trigger('change', this.model);
            }
        }
    }

    errorOnSave(model, response) {
        console.debug('ContractReadUpdate#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
