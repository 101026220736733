import { Collection } from 'backbone';
import config from '@/js/app/config';
import AccountItemModel from '../models/account-item';

export default class AccountItemsCollection extends Collection {
    preinitialize(options) {
        if (!options.group_id) {
            throw new Error('Required option "group_id" is not set')
        }

        this.url = config.api.url + 'freee/account_items/' + options.group_id;

        // Reference to this collection's model.
        this.model = AccountItemModel;
    }
}
