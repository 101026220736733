import dayjs from 'dayjs';
import { View } from 'backbone';
import template from '../templates/select-year-month.hbs';

export default class SelectYearMonth extends View {
    preinitialize(options = {}) {
        this.events = {
            'change [name="year"]': this.loadMonths,
        };

        // Option to set the minimum selectable year (defaults to 2018 when TRT was founded)
        this.minYear = options.minYear || 2018;

        // Option to include next year if current month is December
        this.includeNextYear = options.includeNextYear || false;

        // Option to default to previous month
        this.defaultPreviousMonth = options.defaultPreviousMonth || false;

        // Option to hide months beyond current date
        this.hideFutureMonths = options.hideFutureMonths || false;
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('SelectYearMonth#render');

        this.el.innerHTML = template();

        this.loadDates();
    }


    loadMonths() {
        console.debug('SelectYearMonth#loadMonths');

        const monthSelect = this.el.querySelector('[name="month"]');

        // Clear existing content
        monthSelect.innerHTML = '';

        const options = document.createDocumentFragment();
        const today = dayjs();
        const isSameYearSelected = this.el.querySelector('[name="year"]').value === `${today.year()}`;

        const createOption = (value) => {
            const option = document.createElement('option');
            option.value = value;
            option.innerText = value;
            return option;
        };

        // Max month is current month if hideFutureMonths is set, if current year is selected, and current month is not December (11)
        const maxMonth = this.hideFutureMonths && isSameYearSelected && today.month() !== 11 ? today.month() + 1 : 12;

        for (let m = 1; m <= maxMonth; m++) {
            const value = m.toString().padStart(2, '0');    // Ensure month is 2 digits
            options.appendChild(createOption(value));
        }

        monthSelect.appendChild(options);

        // Determine default date
        let defaultDate = today;
        if (this.defaultPreviousMonth) {
            defaultDate = today.subtract(1, 'month');
        }

        monthSelect.value = isSameYearSelected ? defaultDate.format('MM') : '12';
    }


    loadDates() {
        console.debug('SelectYearMonth#loadDates');

        const yearSelect = this.el.querySelector('[name="year"]');

        const options = document.createDocumentFragment();
        const today = dayjs();

        // If includeNextYear option is set, and month is December (11)
        if (this.includeNextYear && today.month() === 11) {
            const o = document.createElement('option');
            o.value = today.year() + 1;
            o.innerText = today.year() + 1;
            options.appendChild(o);
        }

        for (let y = today.year(); y >= this.minYear; y--) {
            const value = y;
            const o = document.createElement('option');
            o.value = value;
            o.innerText = value;
            options.appendChild(o);
        }

        // Add options to year select
        yearSelect.appendChild(options);

        // Determine default date
        let defaultDate = today;
        if (this.defaultPreviousMonth) {
            defaultDate = today.subtract(1, 'month');
        }

        // Preset year
        yearSelect.value = defaultDate.format('YYYY');

        // Load months and set value
        this.loadMonths();
    }
}