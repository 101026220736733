var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"frmEditContract\" autocomplete=\"off\" novalidate>\n    <div class=\"form-group row align-items-center\">\n        <label class=\"col-form-label col-md-3 col-lg-2 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":70},"end":{"line":3,"column":97}}}))
    + "</label>\n        <div class=\"col-md\">\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"tags\" id=\"tags-size_mismatch\"\n                    value=\"size_mismatch\">\n                <label class=\"form-check-label\" for=\"tags-size_mismatch\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.size_mismatch",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":73},"end":{"line":8,"column":108}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"tags\" id=\"tags-flexible_discount\"\n                    value=\"flexible_discount\">\n                <label class=\"form-check-label\" for=\"tags-flexible_discount\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.flexible_discount",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":77},"end":{"line":14,"column":55}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"tags\" id=\"tags-proxy_contract\"\n                    value=\"proxy_contract\">\n                <label class=\"form-check-label\" for=\"tags-proxy_contract\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.proxy_contract",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":74},"end":{"line":19,"column":110}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"tags\" id=\"tags-payment_method_change\"\n                    value=\"payment_method_change\">\n                <label class=\"form-check-label\" for=\"tags-payment_method_change\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.payment_method_change",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":81},"end":{"line":25,"column":59}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"tags\" id=\"tags-move_campaign\"\n                    value=\"move_campaign\">\n                <label class=\"form-check-label\" for=\"tags-move_campaign\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.move_campaign",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":73},"end":{"line":30,"column":108}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"tags\" id=\"tags-transfer_campaign\"\n                    value=\"transfer_campaign\">\n                <label class=\"form-check-label\" for=\"tags-transfer_campaign\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.transfer_campaign",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":77},"end":{"line":36,"column":55}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"tags\" id=\"tags-referral_campaign\"\n                    value=\"referral_campaign\">\n                <label class=\"form-check-label\" for=\"tags-referral_campaign\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.referral_campaign",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":77},"end":{"line":42,"column":55}}}))
    + "</label>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div id=\"divStatus\" class=\"form-group row\">\n                <label for=\"field-status\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":49}}}))
    + "\n                </label>\n                <div class=\"col-md\">\n                    <select id=\"field-status\" name=\"status\" class=\"form-control\">\n                        <optgroup label=\"契約関係\">\n                            <option value=\"pending\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.pending.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":52},"end":{"line":56,"column":89}}}))
    + "</option>\n                            <option value=\"approved_us\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.approved_us.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":56},"end":{"line":57,"column":97}}}))
    + "</option>\n                            <option value=\"documents_sent\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.documents_sent.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":59},"end":{"line":58,"column":103}}}))
    + "</option>\n                            <option value=\"applied\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":52},"end":{"line":59,"column":89}}}))
    + "</option>\n                            <option value=\"approved_bank\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.approved_bank.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":58},"end":{"line":60,"column":101}}}))
    + "</option>\n                            <option value=\"initial_payment_confirmed\">\n                                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.initial_payment_confirmed.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":32},"end":{"line":62,"column":87}}}))
    + "\n                            </option>\n                            <option value=\"keys_sent\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.keys_sent.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":54},"end":{"line":64,"column":93}}}))
    + "</option>\n                            <option value=\"active\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.active.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":51},"end":{"line":65,"column":87}}}))
    + "</option>\n                        </optgroup>\n                        <optgroup label=\"取消関係\">\n                            <option value=\"withdraw_requested\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.withdraw_requested.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":63},"end":{"line":68,"column":111}}}))
    + "</option>\n                            <option value=\"withdrawn\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.withdrawn.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":54},"end":{"line":69,"column":93}}}))
    + "</option>\n                        </optgroup>\n                        <optgroup label=\"解約関係\">\n                            <option value=\"cancel_applied\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":59},"end":{"line":72,"column":103}}}))
    + "</option>\n                            <option value=\"billing_stopped\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.billing_stopped.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":60},"end":{"line":73,"column":105}}}))
    + "</option>\n                            <option value=\"cancel_short_settled\">\n                                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_short_settled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":32},"end":{"line":75,"column":82}}}))
    + "\n                            </option>\n                            <option value=\"cancel_settled\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_settled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":59},"end":{"line":77,"column":103}}}))
    + "</option>\n                            <option value=\"keys_returned\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.keys_returned.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":58},"end":{"line":78,"column":101}}}))
    + "</option>\n                            <option value=\"cancelled\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancelled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":54},"end":{"line":79,"column":93}}}))
    + "</option>\n                        </optgroup>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"name-picker\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":20},"end":{"line":91,"column":42}}}))
    + "\n                </label>\n                <div class=\"position-relative col-md\">\n                    <div id=\"divCustomer\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div id=\"divUnit\"></div>\n\n    <div class=\"row\" data-part=\"longContract\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-contracted_date\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.contracted_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":20},"end":{"line":106,"column":52}}}))
    + "\n                </label>\n                <div class=\"col-md col-lg-6\">\n                    <input id=\"field-contracted_date\" name=\"contracted_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-withdrawn_date\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.withdrawn_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":117,"column":20},"end":{"line":117,"column":51}}}))
    + "\n                </label>\n                <div class=\"col-md col-lg-6\">\n                    <input id=\"field-withdrawn_date\" name=\"withdrawn_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-start_date\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.start_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":20},"end":{"line":130,"column":47}}}))
    + "\n                </label>\n                <div class=\"col-md col-lg-6\">\n                    <input id=\"field-start_date\" name=\"start_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-payment_due_date\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_due_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":20},"end":{"line":141,"column":53}}}))
    + "\n                </label>\n                <div class=\"col-md col-lg-6\">\n                    <input id=\"field-payment_due_date\" name=\"payment_due_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\" data-part=\"longContract\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-end_date\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.end_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":154,"column":20},"end":{"line":154,"column":45}}}))
    + "\n                </label>\n                <div class=\"col-md col-lg-6\">\n                    <input id=\"field-end_date\" name=\"end_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-vacancy_confirmed_date\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.vacancy_confirmed_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":165,"column":20},"end":{"line":165,"column":59}}}))
    + "\n                </label>\n                <div class=\"col-md col-lg-6\">\n                    <input id=\"field-vacancy_confirmed_date\" name=\"vacancy_confirmed_date\" type=\"date\"\n                        class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-8\">\n            <div class=\"form-group row\">\n                <label for=\"field-notes\" class=\"col-form-label col-md-3 col-lg-3 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.notes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":178,"column":96},"end":{"line":179,"column":38}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <textarea id=\"field-notes\" name=\"notes\" rows=\"10\" type=\"text\" class=\"form-control\"></textarea>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <h3>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.survey_details",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":189,"column":8},"end":{"line":189,"column":39}}}))
    + "</h3>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"survey-initial-source\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":195,"column":20},"end":{"line":195,"column":57}}}))
    + "\n                </label>\n                <div class=\"col-md\">\n                    <select id=\"survey-initial-source\" name=\"survey_initial_source\" data-part=\"initialSelect\"\n                        class=\"form-control\">\n                        <option value=\"\"></option>\n                        <option value=\"dm\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.dm",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":201,"column":43},"end":{"line":201,"column":77}}}))
    + "</option>\n                        <option value=\"post_flyer\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.post_flyer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":202,"column":51},"end":{"line":202,"column":93}}}))
    + "</option>\n                        <option value=\"newspaper_flyer\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.newspaper_flyer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":203,"column":56},"end":{"line":203,"column":103}}}))
    + "</option>\n                        <option value=\"free_paper\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.free_paper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":204,"column":51},"end":{"line":204,"column":93}}}))
    + "</option>\n                        <option value=\"transport_ad\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.transport_ad",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":205,"column":53},"end":{"line":205,"column":97}}}))
    + "</option>\n                        <option value=\"billboard\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.billboard",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":206,"column":50},"end":{"line":206,"column":91}}}))
    + "</option>\n                        <option value=\"see\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.see",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":207,"column":44},"end":{"line":207,"column":79}}}))
    + "</option>\n                        <option value=\"homepage\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.homepage",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":208,"column":49},"end":{"line":208,"column":89}}}))
    + "</option>\n                        <option value=\"portal\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.portal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":209,"column":47},"end":{"line":209,"column":85}}}))
    + "</option>\n                        <option value=\"existing\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.existing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":210,"column":49},"end":{"line":210,"column":89}}}))
    + "</option>\n                        <option value=\"acquaintance\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.acquaintance",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":211,"column":53},"end":{"line":211,"column":97}}}))
    + "</option>\n                        <option value=\"web_search\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.web_search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":212,"column":51},"end":{"line":212,"column":93}}}))
    + "</option>\n                        <option value=\"tv_radio_ad\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.tv_radio_ad",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":213,"column":52},"end":{"line":213,"column":95}}}))
    + "</option>\n                        <option value=\"newspaper_ad\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.newspaper_ad",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":214,"column":53},"end":{"line":214,"column":97}}}))
    + "</option>\n                        <option value=\"other\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.initial_source.other",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":215,"column":46},"end":{"line":215,"column":83}}}))
    + "</option>\n                    </select>\n                    <input type=\"text\" name=\"survey_initial_source_comment\" data-part=\"surveyInitialSourceComment\"\n                        class=\"form-control mt-3 d-none\" disabled>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-intended-duration\" class=\"col-form-label col-lg-4\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.intended_duration.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":228,"column":20},"end":{"line":228,"column":60}}}))
    + "\n                </label>\n                <div class=\"col-lg\">\n                    <select id=\"field-intended-duration\" name=\"intended_duration\" class=\"form-control\">\n                        <option value=\"\"></option>\n                        <option value=\"month_3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.intended_duration.month_3",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":233,"column":48},"end":{"line":233,"column":90}}}))
    + "</option>\n                        <option value=\"month_6\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.intended_duration.month_6",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":234,"column":48},"end":{"line":234,"column":90}}}))
    + "</option>\n                        <option value=\"year_1\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.intended_duration.year_1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":235,"column":47},"end":{"line":235,"column":88}}}))
    + "</option>\n                        <option value=\"year_3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.intended_duration.year_3",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":236,"column":47},"end":{"line":236,"column":88}}}))
    + "</option>\n                        <option value=\"year_5\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.intended_duration.year_5",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":237,"column":47},"end":{"line":237,"column":88}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <div data-part=\"longContract\">\n        <h3>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancellation_survey_details",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":247,"column":12},"end":{"line":247,"column":56}}}))
    + "</h3>\n\n        <div class=\"form-group row\">\n            <label for=\"field-cancel_source\" class=\"col-form-label col-lg-2 text-right\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_source.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":251,"column":16},"end":{"line":251,"column":52}}}))
    + "\n            </label>\n            <div class=\"col-lg-2\">\n                <select id=\"field-cancel_source\" name=\"cancel_source\" class=\"form-control\">\n                    <option value=\"\"></option>\n                    <option value=\"online\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_source.online",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":256,"column":43},"end":{"line":256,"column":80}}}))
    + "</option>\n                    <option value=\"post\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_source.post",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":257,"column":41},"end":{"line":257,"column":76}}}))
    + "</option>\n                </select>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-lg-6\">\n                <div class=\"form-group row\">\n                    <label for=\"field-survey_cancel_reason\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":266,"column":24},"end":{"line":266,"column":60}}}))
    + "\n                    </label>\n                    <div class=\"col-md\">\n                        <select id=\"field-survey_cancel_reason\" name=\"survey_cancel_reason\" data-part=\"cancelSelect\"\n                            class=\"form-control\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCancellation") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":270,"column":49},"end":{"line":270,"column":94}}})) != null ? stack1 : "")
    + ">\n                            <option value=\"\"></option>\n                            <option value=\"moving\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.moving",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":272,"column":51},"end":{"line":272,"column":88}}}))
    + "</option>\n                            <option value=\"renovating\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.renovating",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":273,"column":55},"end":{"line":273,"column":96}}}))
    + "</option>\n                            <option value=\"price\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.price",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":274,"column":50},"end":{"line":274,"column":86}}}))
    + "</option>\n                            <option value=\"facility\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.facility",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":275,"column":53},"end":{"line":275,"column":92}}}))
    + "</option>\n                            <option value=\"service\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.service",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":276,"column":52},"end":{"line":276,"column":90}}}))
    + "</option>\n                            <option value=\"service\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.name_change",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":277,"column":52},"end":{"line":277,"column":94}}}))
    + "</option>\n                            <option value=\"other\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.other",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":278,"column":50},"end":{"line":278,"column":86}}}))
    + "</option>\n                        </select>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-lg-8\">\n                <div class=\"form-group row\">\n                    <label for=\"field-survey_cancel_comment\" class=\"col-form-label col-lg-3 text-right\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_comment",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":289,"column":24},"end":{"line":289,"column":55}}}))
    + "\n                    </label>\n                    <div class=\"col-lg\">\n                        <textarea id=\"field-survey_cancel_comment\" name=\"survey_cancel_comment\" rows=\"10\" type=\"text\"\n                            class=\"form-control\"></textarea>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <hr>\n    </div>\n\n    <h3>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_details",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":302,"column":8},"end":{"line":302,"column":40}}}))
    + "</h3>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"row\">\n                <label for=\"field-existing\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.existing.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":308,"column":20},"end":{"line":308,"column":51}}}))
    + "\n                </label>\n                <div class=\"form-group col-md\">\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-exist-yes\" type=\"radio\" class=\"form-check-input\" name=\"existing\" value=\"1\"\n                            data-number>\n                        <label for=\"field-exist-yes\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.existing.yes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":315,"column":28},"end":{"line":315,"column":57}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-exist-no\" type=\"radio\" class=\"form-check-input\" name=\"existing\" value=\"0\"\n                            data-number>\n                        <label for=\"field-exist-no\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.existing.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":322,"column":28},"end":{"line":322,"column":56}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"row\">\n                <label for=\"field-payment-method\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":334,"column":20},"end":{"line":334,"column":57}}}))
    + "\n                </label>\n                <div class=\"form-group col-md\">\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-credit\" type=\"radio\" class=\"form-check-input\" name=\"payment_method\"\n                            value=\"credit\">\n                        <label for=\"field-credit\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.credit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":341,"column":28},"end":{"line":341,"column":66}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-direct_debit\" type=\"radio\" class=\"form-check-input\" name=\"payment_method\"\n                            value=\"direct_debit\">\n                        <label for=\"field-direct_debit\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.direct_debit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":348,"column":28},"end":{"line":348,"column":72}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-money_transfer\" type=\"radio\" class=\"form-check-input\" name=\"payment_method\"\n                            value=\"money_transfer\">\n                        <label for=\"field-money_transfer\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.money_transfer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":355,"column":28},"end":{"line":355,"column":74}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div data-part=\"longContract\">\n        <div data-part=\"payment-tech\">\n            <div class=\"row\">\n                <div class=\"col-lg-6\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-payment-tech\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.payment_tech.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":369,"column":28},"end":{"line":369,"column":59}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <select id=\"field-payment-tech\" name=\"payment_tech\" class=\"form-control\" readonly disabled>\n                                <option value=\"smbc\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.payment_tech.smbc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":373,"column":53},"end":{"line":373,"column":83}}}))
    + "</option>\n                                <option value=\"br\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.payment_tech.br",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":374,"column":51},"end":{"line":374,"column":79}}}))
    + "</option>\n                            </select>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div data-part=\"bank-details\" class=\"d-none\">\n            <div class=\"row\">\n                <div class=\"col-lg\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-bank-number\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.bank_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":387,"column":28},"end":{"line":387,"column":56}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <input id=\"field-bank-number\" name=\"bank_number\" type=\"text\"\n                                class=\"form-control payment-detail\" maxlength=\"4\" disabled>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"col-lg\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-bank-name\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.bank_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":399,"column":28},"end":{"line":399,"column":54}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <input id=\"field-bank-name\" name=\"bank_name\" type=\"text\" class=\"form-control payment-detail\"\n                                disabled>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row\">\n                <div class=\"col-lg\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-branch-number\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.branch_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":413,"column":28},"end":{"line":413,"column":58}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <input id=\"field-branch-number\" name=\"branch_number\" type=\"text\"\n                                class=\"form-control payment-detail\" maxlength=\"5\" disabled>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"col-lg\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-branch-name\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.branch_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":425,"column":28},"end":{"line":425,"column":56}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <input id=\"field-branch-name\" name=\"branch_name\" type=\"text\"\n                                class=\"form-control payment-detail\" disabled>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row\">\n                <div class=\"col-lg\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-account-type\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.account_type.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":439,"column":28},"end":{"line":439,"column":63}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <select id=\"field-account-type\" name=\"account_type\" class=\"form-control payment-detail\"\n                                data-number disabled>\n                                <option value=\"1\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.account_type.savings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":444,"column":50},"end":{"line":444,"column":87}}}))
    + "</option>\n                                <option value=\"2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.account_type.current",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":445,"column":50},"end":{"line":445,"column":87}}}))
    + "</option>\n                            </select>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"col-lg\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-account-number\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.account_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":454,"column":28},"end":{"line":454,"column":59}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <input id=\"field-account-number\" name=\"account_number\" type=\"text\"\n                                class=\"form-control payment-detail\" maxlength=\"8\" disabled>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row\">\n                <div class=\"col-lg-6\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-account-name\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.account_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":468,"column":28},"end":{"line":468,"column":57}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <input id=\"field-account-name\" name=\"account_name\" type=\"text\"\n                                class=\"form-control payment-detail\"\n                                pattern=\"[A-Z0-9 ｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝｶﾞｷﾞｸﾞｹﾞｺﾞｻﾞｼﾞｽﾞｾﾞｿﾞﾀﾞﾁﾞﾂﾞﾃﾞﾄﾞﾊﾞﾋﾞﾌﾞﾍﾞﾎﾞｳﾞﾊﾟﾋﾟﾌﾟﾍﾟﾎﾟ().-]+\"\n                                maxlength=\"30\" disabled>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"col-lg d-none\" data-part=\"rp-customer-number\">\n                    <div class=\"form-group row\">\n                        <label for=\"field-rp-customer-number\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.rp_customer_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":482,"column":28},"end":{"line":482,"column":63}}}))
    + "\n                        </label>\n                        <div class=\"col-md\">\n                            <input id=\"field-rp-customer-number\" name=\"rp_customer_number\" type=\"text\"\n                                class=\"form-control payment-detail\" disabled>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <div class=\"row align-items-end mb-3\">\n        <div class=\"col-auto\">\n            <p class=\"h5 mb-0\"><strong>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.rate.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":498,"column":39},"end":{"line":498,"column":66}}}))
    + "</strong></p>\n        </div>\n        <div class=\"col-auto\">\n            <button id=\"btnGetCosts\" type=\"button\" class=\"btn btn-secondary \">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.fill",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":501,"column":78},"end":{"line":501,"column":98}}}))
    + "</button>\n        </div>\n    </div>\n\n    <div data-part=\"rates\">\n        <div class=\"row\">\n            <div class=\"col\">\n                <div class=\"form-group row\">\n                    <label for=\"field-base_rate\" class=\"col-form-label col-4 text-right\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.base_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":511,"column":24},"end":{"line":511,"column":50}}}))
    + "\n                    </label>\n                    <div class=\"col-4\">\n                        <div class=\"input-group\">\n                            <div class=\"input-group-prepend\">\n                                <span class=\"input-group-text\">¥</span>\n                            </div>\n                            <input id=\"field-base_rate\" name=\"base_rate\" type=\"text\" class=\"form-control text-right\"\n                                data-number required data-rate=\"flat\">\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col\">\n                <div class=\"form-group row\">\n                    <label for=\"field-campaign_rate\" class=\"col-form-label col-4 text-right\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.campaign_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":529,"column":24},"end":{"line":529,"column":54}}}))
    + "\n                    </label>\n                    <div class=\"col-4\">\n                        <div class=\"input-group\">\n                            <div class=\"input-group-prepend\">\n                                <span class=\"input-group-text\">¥</span>\n                            </div>\n                            <input id=\"field-campaign_rate\" name=\"campaign_rate\" type=\"text\"\n                                class=\"form-control text-right\" data-number data-rate=\"flat\">\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col\">\n                <div class=\"form-group row\">\n                    <label for=\"field-campaign_duration\" class=\"col-form-label col-4 text-right\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_duration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":549,"column":24},"end":{"line":549,"column":54}}}))
    + "\n                    </label>\n                    <div class=\"col-3\">\n                        <div class=\"input-group\">\n                            <input id=\"field-campaign_duration\" name=\"campaign_duration\" type=\"number\"\n                                class=\"form-control text-right\" data-number>\n                            <div class=\"input-group-append\">\n                                <span class=\"input-group-text\">月</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col\">\n                <div class=\"form-group row\">\n                    <label for=\"field-campaign_eligible_min_months\" class=\"col-form-label col-4 text-right\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.campaign_eligible_min_months",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":566,"column":24},"end":{"line":566,"column":65}}}))
    + "\n                    </label>\n                    <div class=\"col-3\">\n                        <div class=\"input-group\">\n                            <input id=\"field-campaign_eligible_min_months\" name=\"campaign_eligible_min_months\"\n                                type=\"number\" class=\"form-control text-right\" data-number>\n                            <div class=\"input-group-append\">\n                                <span class=\"input-group-text\">月</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"field-first_month\" class=\"col-form-label col-2 text-right\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.first_month",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":584,"column":16},"end":{"line":584,"column":44}}}))
    + "\n            </label>\n            <div class=\"col-2\">\n                <div class=\"input-group\">\n                    <div class=\"input-group-prepend\">\n                        <span class=\"input-group-text\">¥</span>\n                    </div>\n                    <input id=\"field-first_month\" name=\"first_month\" type=\"text\" class=\"form-control text-right\"\n                        data-number>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-6\">\n                <div class=\"form-group row\">\n                    <label for=\"field-processing_fee\" class=\"col-form-label col-4 text-right\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.rate.processing_fee",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":602,"column":24},"end":{"line":602,"column":60}}}))
    + "\n                    </label>\n                    <div class=\"col-4\">\n                        <div class=\"input-group\">\n                            <div class=\"input-group-prepend\">\n                                <span class=\"input-group-text\">¥</span>\n                            </div>\n                            <input id=\"field-processing_fee\" name=\"processing_fee\" type=\"text\"\n                                class=\"form-control text-right\" data-number>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col\" data-part=\"directDebitFee\">\n                <div class=\"form-group row\">\n                    <label for=\"field-bank_fee\" class=\"col-form-label col-4 text-right\">\n                        "
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.rate.bank",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":620,"column":24},"end":{"line":620,"column":52}}})) != null ? stack1 : "")
    + "\n                    </label>\n                    <div class=\"col-4\">\n                        <div class=\"input-group\">\n                            <div class=\"input-group-prepend\">\n                                <span class=\"input-group-text\">¥</span>\n                            </div>\n                            <input id=\"field-bank_fee\" name=\"bank_fee\" type=\"text\" class=\"form-control text-right\"\n                                data-number>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-deadline\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.document_deadline",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":640,"column":20},"end":{"line":640,"column":54}}}))
    + "\n                </label>\n                <div class=\"col-md col-lg-6\">\n                    <input id=\"field-deadline\" type=\"date\" class=\"form-control\" readonly>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row justify-content-end align-items-center\">\n        <div class=\"col-md-auto\">\n            <button type=\"button\" class=\"btn btn-secondary btn-block\" data-action=\"cancel\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":651,"column":91},"end":{"line":652,"column":34}}}))
    + "</button>\n        </div>\n        <div class=\"col-md-auto\">\n            <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":655,"column":68},"end":{"line":655,"column":88}}}))
    + "</button>\n        </div>\n    </div>\n</form>";
},"useData":true});